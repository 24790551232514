<template>
  <div class="project-details">
    <div class="container fluid">
      <div v-if="!isEmpty(project)" class="content">
        <!-- =========================== ACTION =========================== -->
        <project-detail-action
          :canEdit="canEdit"
          :canAllocateAgent="false"
          :canAllocateBanker="canAllocateBanker"
          :canAllocateLawyer="canAllocateLawyer"
          @toEditPage="toEditPage"
          @toAgentAllocationPage="toAgentAllocationPage"
          @toBankerAllocationPage="toBankerAllocationPage"
          @toLawyerAllocationPage="toLawyerAllocationPage"
        ></project-detail-action>
        <!-- =========================== HEADER =========================== -->
        <project-detail-header :project="project"></project-detail-header>
        <!-- =========================== PHASES =========================== -->
        <collapsible
          v-if="canEdit"
          v-model="isPhasesOpen"
          class="card p-1 mb-1"
        >
          <template #title>
            <h4>Bank Accounts</h4>
          </template>
          <phases
            type="developer"
            :projectId="$route.params.id"
            :developerId="project.developer.id"
            class="mb-2"
          ></phases>
        </collapsible>

        <!-- ========================= UNIT TYPES ========================= -->
        <unit-types
          type="developer"
          :canAdd="canAdd"
          :canEdit="canEdit"
          :canDelete="canDelete"
          :projectId="$route.params.id"
          :propertyCategory="project.propertyCategory"
          class="mb-2"
        ></unit-types>

        <!-- ============================ BODY ============================ -->
        <project-detail-content :project="project"></project-detail-content>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import projectDetailStore from "@/modules/Project/store/projectDetail";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectAPI from "@/modules/Project/api/project";
import ProjectPhaseAPI from "@/modules/Project/api/projectPhase";
const projectAPI = new ProjectAPI(ManagerRoles.DEVELOPER);
const phaseAPI = new ProjectPhaseAPI(ManagerRoles.DEVELOPER);

export default {
  components: {
    ProjectDetailHeader: () =>
      import("@/modules/Project/components/ProjectDetail/Header"),
    ProjectDetailAction: () =>
      import("@/modules/Project/components/ProjectDetail/Action"),
    ProjectDetailContent: () =>
      import("@/modules/Project/components/ProjectDetail/Content"),
    UnitTypes: () => import("@/modules/Project/components/UnitType/UnitTypes"),
    Phases: () => import("@/modules/Project/components/ProjectPhases/Phases"),

    Collapsible: () => import("@/components/GlobalComponents/Collapsible")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canAdd: false,
      canEdit: false,
      canDelete: false,

      canAllocateBanker: false,
      canAllocateLawyer: false,

      project: {},

      isPhasesOpen: false,

      isEmpty: isEmpty
    };
  },
  computed: {},
  watch: {},
  created: function () {
    this.$store.registerModule("projectDetail", projectDetailStore);
    this.$store.commit("projectDetail/setPhaseAPI", phaseAPI);
    this.init();
  },
  beforeDestroy: function () {
    this.$store.unregisterModule("projectDetail");
  },
  mounted: function () {},
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getProjectDetail();
        this.initPermission();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async initPermission() {
      this.canAdd = this.canEdit = this.canDelete = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjects.modify"
      );
      this.canAllocateBanker = this.canAllocateLawyer = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjectSales.modify"
      );
      this.canViewReport = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "manageProjects.report"
      );
    },

    toEditPage() {
      this.$router.push({
        name: "ManageProjectsEdit",
        id: this.$route.params.id
      });
    },
    toAgentAllocationPage() {
      this.$router.push({
        name: "ProjectAllocateAgents",
        id: this.$route.params.id
      });
    },
    toBankerAllocationPage() {
      this.$router.push({
        name: "ProjectAllocateBankers",
        id: this.$route.params.id
      });
    },
    toLawyerAllocationPage() {
      this.$router.push({
        name: "ProjectAllocateLawyers",
        id: this.$route.params.id
      });
    },

    // ============================== API RELATED ==============================
    async getProjectDetail() {
      try {
        let data = await projectAPI.getProject(this.$route.params.id);

        this.project = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.project-details {
}
</style>
